@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Kid Font Numbers";
  font-weight: 300;
  src: url(/static/media/fontysfirstfont.regular.6588bf80.ttf) format("truetype");
}

@font-face {
  font-family: "Kid Font Characters";
  font-weight: 300;
  src: url(/static/media/PerefectNew-Regular.447e18b8.ttf) format("truetype");
}

p {
  font-size: 1.3rem;
}

.connected {
  margin-right: 2vw;
  color: #000000;
  font-size: 3rem;
}

.connected img {
  width: 5vw;
}

.plz {
  font-size: 5rem;
}

button {
  transition: transform .2s;
  border: none;
  background: none;

}

button:hover {
  transform: scale(2);
  cursor: pointer;
}

.main {
  overflow: hidden;
  font-family: "Kid Font Characters", "Kid Font Numbers", sans-serif;
  min-height: 100vh;
  color: #000000;
  font-weight: 700;
  background-color: #fff7e6;
  opacity: 1;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.perso_img1 {
  width: 53vw;
  position: fixed;
  bottom: -7vh;
  left: -10vw;
  z-index: 0;
}

.perso_img2 {
  width: 53vw;
  position: fixed;
  bottom: -7vh;
  right: -10vw;
  z-index: 0;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 23vh;
  padding: 20px;
  z-index: 10;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
  z-index: 10;
}

.mint_button {
  z-index: 20;
}

.minting_soon {
  width: 80%;
  transition: transform 1s;
  z-index: 10;
}

.minting_soon:hover {
  transform: rotate(360deg);
  cursor: not-allowed;
}

.mint {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.mint img {
  width: 80%;
}

.incrementer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 33vw;
}



.img_container {
  position: relative;
  text-align: center;
  color: black;
}

.img_bg {
  width: 100%;
}

.absolute_input_text {
  visibility: hidden;
}

.absolute_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 20vh;
  width: 100vw;
  position: fixed;
  bottom: 0px;
}

.social {
  display: flex;
  flex-direction: row;
  width: 13vw;
  justify-content: space-around;
}

.c {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }
  
  .perso_img1 {
    width: 70vw;
    position: fixed;
    bottom: 8vh;
    left: -13vw;
    z-index: 0;
  }
  
  .perso_img2 {
    width: 70vw;
    position: fixed;
    bottom: 12vh;
    right: -20vw;
    z-index: 0;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 30vh;
    padding: 20px;
    z-index: 10;
  }

  .title {
    width: 95vw;
  }

  .connect_img {
    width: 70vw;
  }

  .connected img {
    width: 15vw;
  }
  
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .incrementer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 95vw;
  }
  
  .img_container {
    position: relative;
    text-align: center;
    color: black;
  }
  
  .img_bg {
    width: 100%;
  }
  
  .absolute_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
  }

  .plz {
    font-size: 3rem;
  }
  
  .minting_soon {
    width: 80%;
    transition: transform 1s;
    z-index: 10;
  }
  
  .minting_soon:hover {
    transform: rotate(360deg);
    cursor: not-allowed;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 20vh;
    width: 100vw;
    position: fixed;
    bottom: 0px;
  }

  .footer img {
    width: 15vw;
  }
  
  .social {
    display: flex;
    flex-direction: row;
    width: 40vw;
    justify-content: space-around;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
  
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }
  
  .perso_img1 {
    width: 70vw;
    position: fixed;
    bottom: 8vh;
    left: -13vw;
    z-index: 0;
  }
  
  .perso_img2 {
    width: 70vw;
    position: fixed;
    bottom: 12vh;
    right: -20vw;
    z-index: 0;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 30vh;
    padding: 20px;
    z-index: 10;
  }

  .title {
    width: 95vw;
  }

  .connect_img {
    width: 70vw;
  }
  
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  
  .incrementer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 95vw;
  }
  
  .img_container {
    position: relative;
    text-align: center;
    color: black;
  }
  
  .img_bg {
    width: 100%;
  }
  
  
  .absolute_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
  }

  .plz {
    font-size: 3rem;
  }
  
  .minting_soon {
    width: 80%;
    transition: transform 1s;
    z-index: 10;
  }
  
  .minting_soon:hover {
    transform: rotate(360deg);
    cursor: not-allowed;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 15vh;
    width: 100vw;
    position: fixed;
    bottom: 0px;
  }

  .footer img {
    width: 15vw;
  }
  
  .social {
    display: flex;
    flex-direction: row;
    width: 40vw;
    justify-content: space-around;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px){

  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }
  
  .perso_img1 {
    width: 70vw;
    position: fixed;
    bottom: 8vh;
    left: -13vw;
    z-index: 0;
  }
  
  .perso_img2 {
    width: 70vw;
    position: fixed;
    bottom: 12vh;
    right: -20vw;
    z-index: 0;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 30vh;
    padding: 20px;
    z-index: 10;
  }

  .title {
    width: 95vw;
  }

  .connect_img {
    width: 70vw;
  }
  
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  
  .incrementer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 95vw;
  }
  
  .img_container {
    position: relative;
    text-align: center;
    color: black;
  }
  
  .img_bg {
    width: 100%;
  }
  
  .absolute_input_text {
    visibility: hidden;
  }
  
  .absolute_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
  }

  .plz {
    font-size: 3rem;
  }
  
  .minting_soon {
    width: 80%;
    transition: transform 1s;
    z-index: 10;
  }
  
  .minting_soon:hover {
    transform: rotate(360deg);
    cursor: not-allowed;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 15vh;
    width: 100vw;
    position: fixed;
    bottom: 0px;
  }

  .footer img {
    width: 15vw;
  }
  
  .social {
    display: flex;
    flex-direction: row;
    width: 40vw;
    justify-content: space-around;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

.wrapper {
  display: inline-flex;
}

.wrapper .icon {
  position: relative;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color:#f04444;
  border: solid 1px #f04444;
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background-color: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background-color: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .discord:hover,
.wrapper .discord:hover .tooltip,
.wrapper .discord:hover .tooltip::before {
  background-color: #3b5999;
  color: #ffffff;
  border: none;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background-color: #46c1f6;
  color: #ffffff;
  border: none;
}
